<script src="../tools/wxapp_rsa.js"></script>
<template>
  <div id="mainPage">
    <div class="topBC">
      <img src="../static/images/icon_charge_title.png" width="55%" height="25px"/>
      <div class="title">
        <span>用户充值</span>
        <img src="../static/images/logo_goAllOutMan.png" width="69px" height="86px"/>
        <em v-if="false" class="button" @click="onGoNewPage('testMemberNavi')">主页</em>
      </div>
    </div>

    <!--内容区域-->
    <div class="context">
      <!--充值状态-->
      <div class="area_title" v-if="status == 0">
        <span style="color: orange">查询结果中 {{ timeWait }}...</span>
      </div>

      <div v-if="status == 1">
        <!--充值状态-->
        <div class="area_title">
          <img src="../static/images/icon_charge_true.png" width="24px" height="24px"/>
          <span>交易成功</span>
        </div>

        <!--充值信息-->
        <div class="area_info">
          <div class="trade_info">
            <span>会员名称:</span>
            <em>{{ chargeInfo.UserName }}</em>
          </div>
          <div class="trade_info">
            <span>手机号码:</span>
            <em>{{ chargeInfo.Phone }}</em>
          </div>
          <div class="trade_info" v-if="chargeInfo.ChargeNormalMonth">
            <span>购买时长:</span>
            {{ chargeInfo.ChargeNormalMonth }}<em>个月</em>
          </div>
          <div class="trade_info" v-if="chargeInfo.ChargeGiftMonth">
            <span>赠送时长:</span>
            {{ chargeInfo.ChargeGiftMonth }}<em>个月</em>
          </div>
          <div class="trade_info" v-if="chargeInfo.ChargeNormalClassHour">
            <span>购买课时:</span>
            {{ chargeInfo.ChargeNormalClassHour }}<em>课时</em>
          </div>
          <div class="trade_info" v-if="chargeInfo.ChargeGiftClassHour">
            <span>赠送课时:</span>
            {{ chargeInfo.ChargeGiftClassHour }}<em>课时</em>
          </div>
          <div class="trade_info" v-if="chargeInfo.InviteCodeGiftClassHour > 0">
            <span>首冲赠送课时:</span>
            {{ chargeInfo.InviteCodeGiftClassHour }}<em>课时</em>
          </div>
          <div class="trade_info" v-if="chargeInfo.TotalFee">
            <span>交易金额:</span>
            {{ chargeInfo.TotalFee/100 }}<em>元</em>
          </div>
          <div class="trade_info">
            <span>下单时间:</span>
            <em>{{ chargeInfo.ChargeTime |filterTime }}</em>
          </div>
        </div>
      </div>
    </div>

    <div v-if="status == 1" class="backButton" @click="onGoNewPage('membershipCharge')">
      返回充值
    </div>


    <alert ref="alertPart"></alert>
  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/vue/dist/vue.js"></script>
<script>
import {
  ChargeInfoQuery,
} from '../api/getApiRes.js'

import bottomTab from '../components/bottomTab'
import alert from '../components/alert'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';

let qs = require('qs');

export default {
  data() {
    return {
      timer: '',
      timeWait: '',
      openId: '',
      status: 0,
      outTradeNo: '',
      chargeInfo:[],
    }
  },
  mounted() {
    this.timeWait = 0;
    this.openId = localStorage.openId;
    this.outTradeNo = this.$route.query.OutTradeNo;
    this.getChargeResult();
  },
  destroyed() {
  },
  watch: {
    $route(to) {
      if (to.name == 'membershipChargeResult') {
        this.timeWait = 0;
        this.openId = local.openId;
        this.outTradeNo = this.$route.query.OutTradeNo;
        this.getChargeResult();
      }
    },
    'active'() {
      this.getList();
    }
  },
  methods: {
    alertInfo(info) {
      this.$refs.alertPart.openSimpleDialog(info);
    },
    //查询订单信息
    getChargeResult() {
      let that = this;
      let param = {
        outTradeNo: that.outTradeNo,
        openId: that.openId
      };
      let postdata = qs.stringify(param);
      ChargeInfoQuery(postdata).then(res => {
        let json = res;
        console.log(json);
        if (json.Code == 0) {
          that.status = json.Rs.Status;
          if (that.status == 1) {
            that.chargeInfo = json.Rs;
          } else {
            //启动计时器，1秒执行一次
            that.timer = setInterval(function () {
              that.Toast('正在努力查询中请稍后');
              that.timeWait++;
              that.getChargeResult();
              clearInterval(that.timer);//停止计时器
            }, 1000);
          }
        } else {
          that.Toast(json.Memo);
        }
      })
    },
    onGoNewPage(path) {
      this.$router.push({path: '/' + path});
    },
  },
  filters: {
    filterTime: function (value) {
      if (!value) {
        return 0;
      } else {
        return dateFormatWithoutZone(value, 16);
      }
    },
  },
  beforeDestroy() {
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (to.name == '') {
        vm.getList();
      }
    });
  },
  components: {
    bottomTab, alert, DatePicker
  }
}
</script>

<style scoped>
#mainPage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  background: #f2f2f2;
  padding-bottom: 50px;
  overflow-y: scroll;
}

.topBC {
  height: 120px;
  width: 100%;
  background: red;
  background: url('../static/images/bc_charge.png') no-repeat center;
  text-align: center;
}

.topBC img {
  margin-top: 11px;
}

.topBC .title {
  height: 35px;
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title span {
  font-size: 30px;
  color: white;
}

.title img {
  width: 28px;
  height: 35px;
  margin-top: -5px;
  margin-left: 5px;
}

.title .button {
  width: 50px;
  font-size: 14px;
  height: 25px;
  background: rgba(174, 161, 161, 0.68);
  display: block;
  text-align: center;
  line-height: 25px;
  color: white;
  margin-left: 5%;
  margin-top: -100px;
  border-radius: 3px;
}

.context {
  width: 100%;
  background-color: #F2F2F2;
  padding-top: 1px;
  border-radius: 10px;
  padding-bottom: 30px;
}

.context .area_title {
  margin-top: 5px;
  font-size: 17px;
  color: #37CB00;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  height: 60px;
  width: 100%;
}

.context .area_title span {
  margin-left: 5px;
}

.context .area_info {
  font-size: 17px;
  background: #FFFFFF;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 15px;
  width: 100%;
}


.area_info .trade_info {
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  line-height: 40px;
  color: #FF5800;
}

.trade_info span {
  font-size: 16px;
  font-weight: normal;
  color: #363636;
  margin-right: 5px;
  margin-left: 10%;
}

.trade_info em {
  font-weight: normal;
  font-size: 16px;
  margin-left: 5px;
  color: #909090;
}

.backButton {
  height: 60px;
  background: #FFFFFF;
  width: 95%;
  font-size: 16px;
  color: #363636;
  border-radius: 6px;
  text-align: center;
  line-height: 60px;
  margin: 5px auto 20px;
}


::-webkit-input-placeholder {
  color: #ffb8d9;
}

/*响应式调整*/
@media only screen and (max-width: 320px) {
}

@media (min-width: 321px) and (max-width: 344px) {
}

@media (min-width: 345px) and (max-width: 360px) {
}

@media (min-width: 361px) and (max-width: 375px) {
}

@media (min-width: 376px) and (max-width: 396px) {
}

@media (min-width: 397px) and (max-width: 414px) {
}

@media (min-width: 415px) and (max-width: 480px) {
}

@media (min-width: 481px) and (max-width: 640px) {
}
</style>
